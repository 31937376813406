import React from 'react';

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'

import AccordionBase from '../../components/accordion';
import Accordion from 'react-bootstrap/Accordion';

import FormationText from '../../components/formation-text';

import '../formations-page.css'
import HelmetWidget from '../../components/helmet';

const FormationDiagnostiqueur = () => {
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Formation Diagnostiqueur"
				description="La formation Risque Électrique a pour finalité de doter les participants des connaissances et compétences indispensables pour occuper le poste d'Exécutant Électrique. Structurée sur plusieurs niveaux, elle aborde les aspects de réglementation, de sécurité électrique, et de mesures de protection."
				path="formations/formation-diagnostiqueur"
			></HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero titre-formation">
				<h1 className="title-text">FORMATION DIAGNOSTIQUEUR</h1>
			</div>
			<div className="content-div">
				<div className="accordions-div">
					<img src='/images_formation/a-venir.png' alt="diagnostiqueur" className="formation-image"></img>
				</div>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default FormationDiagnostiqueur