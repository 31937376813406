import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, query, orderBy } from 'firebase/firestore';
import { format } from 'date-fns';

import { Table } from 'react-bootstrap';
import { cong } from '../firebase/config';
import Swal from 'sweetalert2';

const DateTable = (props) => {
  const [modules, setModules] = useState([]);

  useEffect(() => {
    const fetchModulesAndDates = async () => {
      try {
        const db = getFirestore(cong); // Récupérer l'instance Firestore
        const modulesCollection = collection(db, '/dateFormation/'+props.formation+'/modules');
        const queryModules = query(modulesCollection);
        const modulesSnapshot = await getDocs(queryModules);

        const fetchedModules = await Promise.all(
          modulesSnapshot.docs.map(async (moduleDoc) => {
            const moduleData = moduleDoc.data();
            const datesCollection = collection(moduleDoc.ref, 'dates');
            const datesQuery = query(datesCollection, orderBy('date'));
            const datesSnapshot = await getDocs(datesQuery);

            const dates = datesSnapshot.docs.map((dateDoc) => {
                const debut = dateDoc.data()["date"][0];
                const fin = dateDoc.data()["date"][1];
                const nbPlaces = dateDoc.data()["nbPlaces"] || 10;
                const detailDate = dateDoc.data()["detailDate"] || "";
                return {
                  id: dateDoc.id,
                  debut: debut.toDate(),
                  fin: fin.toDate(),
                  nbPlaces : nbPlaces,
                  detailDate : detailDate
                };
            });

            return {
              moduleName: moduleData.name || moduleDoc.id, // Récupérer le nom du module, ou utiliser l'id
              dates,
            };
          })
        );
        setModules(fetchedModules);
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    };
    fetchModulesAndDates();
  }, []);

  const formatDate = (date) => format(date, 'dd/MM/yyyy');

  const showPopup = (detailDate) => {
    Swal.fire({
      title: 'Informations complémentaires',
      text: detailDate,
      confirmButtonText: 'OK',
    });
  }

  return (
    <div style={{ maxWidth: '600px', overflowX: 'auto' }}>
      <h3>Prochaines dates</h3>

    {modules.every(module => module.dates.length === 0) ? (
      <p>Nous contacter pour plus d'informations</p>
    ) :
      <Table striped bordered hover responsive='sm'>
        <thead>
          <tr>
            <th className="text-center">Module</th>
            <th className="text-center">Date de début</th>
            <th className="text-center">Date de fin</th>
            <th className="text-center">Places disponibles</th>
          </tr>
        </thead>
        <tbody>
          {modules.map((module, moduleIndex) => (
            module.dates.map((item, dateIndex) => (
              <tr key={item.id}>
                {dateIndex === 0 && (
                  <td className="text-center" rowSpan={module.dates.length} style={{ fontWeight: 'bold' }}>
                    {module.moduleName}
                  </td>
                )}
                <td className="text-center">{formatDate(item.debut)}</td>
                <td className="text-center">{formatDate(item.fin)}</td>
                <td className="text-center">{item.nbPlaces}</td>
                {/* {
                  item.detailDate != "" ? (
                    <td className="text-center">
                      <FontAwesomeIcon icon={faCircleInfo} onClick={showPopup(item.detailDate)}/>
                    </td>
                  ) : <td></td>
                } */}
              </tr>
            ))
          ))}
        </tbody>
      </Table>}
    </div>
  );

  return (
    <Container className="my-4" style={{ maxWidth: '600px' }}>
      <h2 className="text-center mb-4">Dates des Modules</h2>

      <Table striped bordered hover responsive="sm">
        <thead>
          <tr>
            <th className="text-center">Date de début</th>
            <th className="text-center">Date de fin</th>
          </tr>
        </thead>
        <tbody>
          {modules.map((module, moduleIndex) => (
            <React.Fragment key={module.moduleName}>
              {/* Séparateur visuel avec le nom du module */}
              <tr>
                <td colSpan="2" className="text-center font-weight-bold bg-light">
                  {module.moduleName}
                </td>
              </tr>

              {module.dates.map((item) => (
                <tr key={item.id}>
                  <td className="text-center">{formatDate(item.debut)}</td>
                  <td className="text-center">{formatDate(item.fin)}</td>
                </tr>
              ))}

            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default DateTable;