import React from 'react';

import { Helmet } from 'react-helmet'

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'

import AccordionBase from '../../components/accordion';
import Accordion from 'react-bootstrap/Accordion';

import FormationText from '../../components/formation-text';

import '../formations-page.css'
import HelmetWidget from '../../components/helmet';
import FooterFormation from '../../components/footer-formation';
import DateTable from '../../components/dateTable';

const FormationSS3 = () => {
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Formation Amiante SS3"
				description="La formation sous section 3 (SS3) vise à doter les participants des connaissances et compétences nécessaires pour intervenir dans des travaux de retrait ou d'encapsulage de materiaux contenant de l'amiante. Connaissance des risques, l'application des procédures opératoires, et le respect des normes réglementaires."
				path="formations/formation-ss3"
			>
			</HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero titre-formation">
				<h1 className="title-text">FORMATION AMIANTE SS3</h1>
			</div>
			<div className="content-div">
				<div className="accordions-div">
					<div className='only_mobile'>
						<DateTable formation="SS3"/>
					</div>
					<Accordion defaultActiveKey="100">
						<AccordionBase
							numberKey="0"
							titre="FORMATION INITIALE | OPERATEUR DE CHANTIER"
							duree="5 jours / 35 heures"
							prix="990 € HT"
							objectif={["Être capable d'appliquer les procédures opératoires spécifiques au type d'activité exercée pour la préparation, la réalisation, la restitution des chantiers et les procédures de contrôle."]}
							participants={[`Personnel chargé d’exécuter des travaux et/ou d’installer, de faire fonctionner et d’entretenir
							les matériels qui lui sont confiés dans le respect des procédures, du plan de retrait,
							d’encapsulage ou du mode opératoire.`]}
							preRequis={[
								`Détenir une attestation médicale d’aptitude au poste de travail à jour délivrée par le médecin du
								travail (ou par le médecin traitant pour les personnels non-salariés permettant le port d’un
								appareil de protection respiratoire)
								`, 
								`A l'occasion de cette formation le salarié est mis en situation de travail avec port d'équipement de
								protection respiratoire. L'attention des employeurs est attirée sur l'opportunité d'entrer en
								contact avec le médecin du travail pour décider du suivi et des mesures adaptées tant,
								éventuellement, pour le suivi de la formation que pour l'activité en entreprise.
								`
							]}							programme={["Caractéristiques et propriétés de l'amiante.", "Réglementation et dispositions pénales en matière d'interdiction et de prévention du risque amiante.", "Identifier les produits et dispositifs susceptibles de contenir de l'amiante.", "Appliquer les méthodes de travail et les procédures opératoires.Equipements de protection collective (EPC) et équipements de protection individuelle (EPI).", "Zone confinée ; équipements, condition de travail, procédures d'entrée et sortie.", "Gestion des déchets contenant de l'amiante.", "Situations d'urgence et/ou anormales et alerte du personnel encadrant."]}
							recyclage="Attention: Un premier recyclage est à effectuer dans un délai de 6 mois après la formation initiale. Un recyclage doit ensuite être effectué tous les 3 ans."
						></AccordionBase>
						<AccordionBase
							numberKey="1"
							titre="FORMATION INITIALE | ENCADRANT DE CHANTIER"
							duree="10 jours / 70 heures"
							prix="2490 € HT"
							objectif={["Être capable d'appliquer un plan de démolition, de retrait ou de confinement d'amiante et connaître les notions d'aéraulique.", "Être capable d'appliquer les procédures opératoires spécifiques au type d'activité exercée pour la préparation, la conduite, la restitution des chantiers et les procédures de contrôle."]}
							participants={[`Travailleur ayant, au sein de l’entreprise, les compétences nécessaires pour diriger et
							coordonner l’exécution des travaux, mettre en œuvre le plan de retrait ou de confinement, ou
							le mode opératoire.
							`]}
							preRequis={[
								`Détenir une attestation médicale d’aptitude au poste de travail à jour délivrée par le médecin du
								travail (ou par le médecin traitant pour les personnels non-salariés permettant le port d’un
								appareil de protection respiratoire)
								`, 
								`A l'occasion de cette formation le salarié est mis en situation de travail avec port d'équipement de
								protection respiratoire. L'attention des employeurs est attirée sur l'opportunité d'entrer en
								contact avec le médecin du travail pour décider du suivi et des mesures adaptées tant,
								éventuellement, pour le suivi de la formation que pour l'activité en entreprise.
								`
							]}
							programme={["Caractéristiques et propriétés de l'amiante.", "Réglementation et dispositions pénales en matière d'interdiction et de prévention du risque amiante.", "Identifier et traiter les produits, dispositifs et matériaux susceptibles de contenir de l'amiante.", "Application des conclusions de l'évaluation des risques et définition des procédures opératoires.", "Méthode de réduction d'émission de fibres d'amiante et procédure de contrôle.", "Procédures d'entrée et de sortie de zone confinée et de décontamination.", "Équipements de protection collective (EPC) et équipements de protection individuelle (EPI).", "Appliquer des mesures correctives nécessaires à la bonne réalisation des chantiers.", "Gestion des déchets contenant de l'amiante.", "Identifier et gérer des situations d'urgence et/ou anormales."]}
							recyclage="Attention: Un premier recyclage est à effectuer dans un délai de 6 mois après la formation initiale. Un recyclage doit ensuite être effectué tous les 3 ans."
						></AccordionBase>
						<AccordionBase
							numberKey="2"
							titre="FORMATION INITIALE | ENCADRANT TECHNIQUE"
							duree="10 jours / 70 heures"
							prix="2490 € HT"
							objectif={["Connaître les moyens techniques et matériels permettant de maîtriser l'aéraulique d'un chantier.", "Être capable de définir et de faire appliquer des procédures opératoires adaptées et spécifiques de l'activité exercée pour la préparation, la conduite et la restitution des chantiers.", "Être capable de définir des procédures de contrôle en cours de chantier et de les faire appliquer."]}
							participants={[`Employeur ou toute personne possédant, au sein de l’entreprise, une responsabilité au niveau
							des prises de décisions technico-commerciales, des études, de l’établissement des documents
							techniques ou contractuels, de la définition, de l’organisation et de la mise en œuvre des
							spécifications et des moyens techniques`]}
							preRequis={[
								`Détenir une attestation médicale d’aptitude au poste de travail à jour délivrée par le médecin du
								travail (ou par le médecin traitant pour les personnels non-salariés permettant le port d’un
								appareil de protection respiratoire)
								`, 
								`A l'occasion de cette formation le salarié est mis en situation de travail avec port d'équipement de
								protection respiratoire. L'attention des employeurs est attirée sur l'opportunité d'entrer en
								contact avec le médecin du travail pour décider du suivi et des mesures adaptées tant,
								éventuellement, pour le suivi de la formation que pour l'activité en entreprise.
								`
							]}							
							programme={["Caractéristiques et propriétés de l'amiante.", "Réglementation et dispositions pénales en matière d'interdiction et de prévention du risque amiante.", "Évaluation des risques.", "Produits, dispositifs et matériaux susceptibles de contenir de l'amiante; identification et traitement.", "Méthodes de réduction d'émission de fibres d'amiantes, procédures de contrôle de l'empoussièrement, suivi des expositions et procédures de décontamination.", "Équipements de protection collective (EPC) et équipements de protection individuelle (EPI).", "Gestion des déchets contenant de l'amiante.", "Identification et gestion des situations d'urgence et/ou anormales."]}
							recyclage="Attention: Un premier recyclage est à effectuer dans un délai de 6 mois après la formation initiale. Un recyclage doit ensuite être effectué tous les 3 ans."
						></AccordionBase>
						<AccordionBase
							numberKey="3"
							titre="FORMATION 1ER RECYCLAGE | OC-EC-ET"
							duree="2 jours / 14 heures"
							prix="590 € HT"
							objectif={["Contrôle de l'assimilation des enseignements de la formation initiale.", "Retour d'expérience issu de sa première période d'exercice professionnel.", "Renforcer et actualiser les aspects de prévention liés aux risques liés à l'amiante."]}
							participants={["La formation est destinée aux employeurs et travailleurs qui réalisent des travaux de retrait ou d'encapsulage de matériaux contenant de l'amiante. Les employeurs et travailleurs auront, au préalable, suivi la formation initiale << Risque amiante sous-section 3 >> - décret du 4/05/2012."]}
							preRequis={["Détenir une attestation de compétences amiante SS3 préalable de moins de six mois."]}
							programme={["Retour d'expérience et bilan des activités. Analyse des besoins des participants.", "Choix de thématiques selon les besoins des participants.", "Remise à niveau des connaissances risques amiante, évaluation des risques, consignes de sécurité, confinement, élaboration des modes opératoire et méthodologie* et leur application**, gestion des déchets...", "Remise à niveau des références législatives et réglementaires.", "*spécifique aux personnels encadrants.", "**spécifique aux personnels opérateurs."]}
						></AccordionBase>
						<AccordionBase
							numberKey="4"
							titre="FORMATION RECYCLAGE TRIENNAL | OC-EC - ET"
							duree="2 jours / 14 heures"
							prix="590 € HT"
							objectif={["Mise à jour des connaissances en tenant compte de l'évolution des techniques et de la réglementation.", "Retour d'expérience.", "Renforcer les aspects de prévention liés aux risques liés à l'amiante."]}
							participants={["La formation est destinée aux employeurs et travailleurs qui réalisent des travaux de retrait ou d'encapsulage de matériaux contenant de l'amiante. Les employeurs et travailleurs auront, au préalable, suivi la formation initiale ainsi que le 1er Recyclage à 6 mois « Risque amiante sous-section 3 »>- décret du 4/05/2012."]}
							preRequis={["Attestation de formation initiale « Risque amiante sous-section 3 ».", "Attestation de 1er Recyclage à 6 mois."]}
							programme={["Retour d'expérience. Analyse des besoins des participants.", "Choix de thématiques selon les besoins des participants.", "Remise à niveau des connaissances risques amiante, évaluation des risques, consignes de sécurité, confinement, bilan aéraulique*, plan de retrait, contrôle des chantiers*, élaboration des modes opératoires et méthodologie* et leur application**, gestion des déchets...", "Remise à niveau des références législatives et règlementaires.", "*spécifique aux personnels encadrants.", "** spécifique aux personnels opérateurs."]}
							recyclage="Attention: Un recyclage triennal est recommandé pour maintenir la validité de la formation."
						></AccordionBase>
					</Accordion>
					<FooterFormation
						nomFormation="SS3"
						listeFormations={["OPERATEUR DE CHANTIER", "ENCADRANT DE CHANTIER", "ENCADRANT TECHNIQUE", "1ER RECYCLAGE", "RECYCLAGE TRIENNAL"]}
						downloadLink={""}
					></FooterFormation>
				</div>
				<FormationText
					objectif="Former et actualiser les compétences des travailleurs intervenant dans des travaux de retrait ou d'encapsulage de materiaux contenant de l'amiante. Elle couvre les aspects essentiels tels que la connaissance des risques, l'application des procédures opératoires, et le respect des normes réglementaires."
					downloadLink="SS3.pdf"
					nomFormation="SS3"
					listeFormations={["OPERATEUR DE CHANTIER", "ENCADRANT DE CHANTIER", "ENCADRANT TECHNIQUE", "1ER RECYCLAGE", "RECYCLAGE TRIENNAL"]}
					tauxReussite="86 %"
				></FormationText>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default FormationSS3
