import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';

import PropTypes from 'prop-types';

import "../style.css"
import "./accordion.css"


const AccordionBase = (props) => {
	const txtArray = props.titre.split("|");
	const display = txtArray.length > 1 ? <div>{txtArray[0]}|<br className='only_mobile' />{txtArray[1]}</div> : txtArray[0];
	return (
		<Accordion.Item eventKey={props.numberKey} color="primary">
			<Accordion.Header>{display}</Accordion.Header>
			<Accordion.Body>
				<Alert className="alert-prix">
					Durée : {props.duree}
					<br />
					Coût : {props.prix}
				</Alert>
				{props.objectif.length > 0 && <div>
					<h5>Objectifs</h5>
					<ul>
						{props.objectif.map((element, index) => (
							<li key={index}>{element}</li>
						))}
					</ul>
				</div>}
				{props.participants.length > 0 && <div>
					<h5>Participants</h5>
					<ul>
						{props.participants.map((element, index) => (
							<li key={index}>{element}</li>
						))}
					</ul>
				</div>}
				{props.preRequis.length > 0 && <div>
					<h5>Pré-requis</h5>
					<ul>
						{props.preRequis.map((element, index) => (
							<li key={index}>{element}</li>
						))}
					</ul>
				</div>}
				{props.programme.length > 0 && <div>
					<h5>Programme</h5>
					<ul>
						{props.programme.map((element, index) => (
							<li key={index}>{element}</li>
						))}
					</ul>
				</div>}
				{props.recyclage != "" && <div>
					<p>{props.recyclage}</p>
				</div>}
			</Accordion.Body>
		</Accordion.Item>
	);
}

AccordionBase.defaultProps = {
	numberKey: '0',
	titre: '',
	duree: '',
	prix: '',
	objectif: [],
	participants: [],
	preRequis: [],
	programme: [],
	recyclage: ''
}

AccordionBase.propTypes = {
	numberKey: PropTypes.string,
	titre: PropTypes.string,
	duree: PropTypes.string,
	prix: PropTypes.string,
	objectif: PropTypes.array,
	participants: PropTypes.array,
	preRequis: PropTypes.array,
	programme: PropTypes.array,
	recyclage: PropTypes.string
}

export default AccordionBase;