import React from 'react';

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'

import AccordionBase from '../../components/accordion';
import Accordion from 'react-bootstrap/Accordion';

import FormationText from '../../components/formation-text';

import '../formations-page.css'
import HelmetWidget from '../../components/helmet';
import FooterFormation from '../../components/footer-formation';

const FormationSecuriteIncendie = () => {
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Formation Sécurité Incendie"
				description="La formation Sécurité Incendie a pour objectif de fournir aux participants les connaissances et compétences essentielles pour assumer le rôle d'Agent de Sécurité Incendie. Elle englobe différents niveaux, du débutant à l'expert, couvrant les aspects de prévention, de détection et de lutte contre l'incendie."
				path="formations/formation-securite-incendie"
			>
			</HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero titre-formation">
				<h1 className="title-text">FORMATION SÉCURITÉ INCENDIE</h1>
			</div>
			<div className="content-div">
				<div className="accordions-div">
					<Accordion defaultActiveKey="100">
						<AccordionBase
							numberKey="0"
							titre="SSIAP - Niveau 1"
							duree="10 jours / 70 heures"
							prix="790 € HT"
							objectif={[
								"Acquérir une instruction technique spécialisée en matière de prévention, de détection et de lutte contre l’incendie, ainsi que d’entretien des moyens de secours.",
								"Préparer l’examen en vue d’obtenir le certificat de qualification d’Agent de sécurité incendie SSIAP 1 délivré par Bureau Preventicas, organisme agréé."
							]}
							participants={[
								"Personnes désirant acquérir la qualification d’Agent de sécurité incendie SSIAP 1."
							]}
							preRequis={[
								"Être titulaire de l’attestation de AFPS/PSC 1 depuis moins de 2 ans ou du SST/PSE 1 en cours de validité.",
								"Satisfaire à une évaluation de la capacité du candidat à rédiger sur la main courante les anomalies constatées lors d’une ronde et à alerter les secours.",
								"Être apte physiquement, attestée par un certificat médical datant de moins de 3 mois, conformément à l’arrêté du 2 mai 2005 (annexe VII)."
							]}
							programme={[
								"Connaître le comportement du feu et son action sur l’établissement.",
								"Connaître les principes de la réglementation incendie dans les ERP et les IGH.",
								"Connaître les installations techniques sur lesquelles il est susceptible d’intervenir.",
								"Effectuer l’entretien de base des principaux matériels de sécurité incendie.",
								"Connaître les limites de son action.",
								"Effectuer l’extinction des feux naissants.",
								"Visites applicatives.",
								"Mise en situation d’intervention.",
								"Examen final en vue de l’obtention du certificat de qualification d’Agent de sécurité incendie SSIAP 1."
							]}
							recyclage="Un recyclage est obligatoire tous les 3 ans afin de maintenir sa qualification et pouvoir exercer la fonction d’Agent de sécurité incendie."
						></AccordionBase>
						<AccordionBase
							numberKey="1"
							titre="SSIAP - Niveau 2"
							duree="10 jours / 70 heures"
							prix="890 € HT"
							objectif={[
								"Acquérir une instruction technique spécialisée en matière de prévention, de détection et de lutte contre l’incendie, ainsi que d’entretien des moyens de secours.",
								"Se préparer à l’examen en vue d’obtenir le certificat de qualification de Chef d’équipe sécurité incendie SSIAP 2 délivré par Bureau Preventicas, organisme agréé."
							]}
							participants={[
								"Agents de sécurité permanents des services de sécurité incendie des établissements recevant du public (ERP) ou des immeubles de grande hauteur (IGH).",
								"Personnel permanent des services de sécurité incendie souhaitant accéder à la fonction de Chef d’équipe sécurité incendie ERP-IGH."
							]}
							preRequis={[
								"Être titulaire du SSIAP 1 ou équivalence.",
								"Avoir exercé l’emploi d’agent de service de sécurité incendie pendant au moins 1607 h durant les 2 dernières années (attesté par l’employeur ou contrat de travail).",
								"Être titulaire de l’attestation AFPS/PSC 1 de moins de 2 ans ou SST/PSE 1 en cours de validité.",
								"Être apte physiquement, attestée par un certificat médical datant de moins de 3 mois, conformément à l’arrêté du 2 mai 2005 (annexe VII)."
							]}
							programme={[
								"Connaître les outils permettant la gestion et le management d’une équipe.",
								"Connaître les tableaux de signalisation sur lesquels il est susceptible d’intervenir, identifier et interpréter les différents signaux.",
								"Connaître les dispositions applicables en hygiène et sécurité du travail en matière de sécurité incendie.",
								"Connaître les commissions de sécurité incendie et accessibilité.",
								"Connaître les procédures et les consignes.",
								"Gérer les intervenants.",
								"Prendre les décisions adaptées.",
								"Connaître et mettre en action les moyens visant à faciliter l’action des sapeurs-pompiers.",
								"Examen final en vue de l’obtention du certificat de qualification de Chef d’équipe de sécurité incendie SSIAP 2."
							]}
							recyclage="Un recyclage est obligatoire tous les 3 ans conformément à l’arrêté du 2 mai 2005, afin de maintenir sa qualification et pouvoir exercer la fonction de Chef d’équipe sécurité incendie."
						>
						</AccordionBase>
						<AccordionBase
							numberKey="2"
							titre="SSIAP - Niveau 3"
							duree="30 jours"
							prix="3 590 € HT"
							objectif={[
								"Être capable d’assumer efficacement la fonction de Chef de service de sécurité incendie.",
								"Acquérir une instruction technique spécialisée en matière de prévention, de détection et de lutte contre l’incendie, ainsi que d’entretien des moyens de secours.",
								"Se préparer à l’examen en vue d’obtenir le certificat de qualification de Chef de service sécurité incendie SSIAP 3 délivré par Bureau Preventicas, organisme agréé."
							]}
							participants={[
								"Personnel permanent des services de sécurité incendie souhaitant accéder à la fonction de Chef de service sécurité incendie ERP-IGH."
							]}
							preRequis={[
								"Être titulaire d’un diplôme de niveau 4 (IV) minimum, pouvant être obtenu par la validation des acquis de l’expérience (VAE).",
								"Ou être titulaire d’un diplôme SSIAP 2, ERP 2 ou IGH 2 délivré avant le 31 décembre 2005 et justifier de 3 ans d’expérience dans la fonction, attestée par l’employeur ou contrat de travail.",
								"Être titulaire de l’attestation AFPS/PSC 1 de moins de 2 ans ou SST/PSE 1 en cours de validité."
							]}
							programme={[
								"Les principes d’éclosion et de développement du feu.",
								"Définir et appliquer la réaction et la résistance au feu.",
								"Reconnaître la typologie et le type de structure d’un bâtiment.",
								"Se situer sur un plan d’architecte en vue d’appliquer la réglementation incendie.",
								"Connaître et savoir utiliser la trame d’analyse d’un projet de construction.",
								"Connaître et savoir utiliser la trame d’analyse d’un projet d’aménagement ou de réaménagement.",
								"Expliquer l’ordonnancement de la réglementation.",
								"Classer un bâtiment en fonction de la réglementation.",
								"Appliquer les obligations réglementaires aux différents types de bâtiments.",
								"La réglementation “Accessibilité aux personnes handicapées”.",
								"Analyse des risques.",
								"Réalisation des travaux de sécurité.",
								"Documents administratifs.",
								"Effectuer un compte-rendu oral ou écrit et rédiger un rapport.",
								"Actualiser sa connaissance des textes applicables.",
								"La composition, le rôle des commissions de sécurité et l’importance des relations avec ces commissions.",
								"Contrôler et tenir le registre de sécurité incendie.",
								"Les principes de gestion du personnel et des moyens.",
								"Assurer une autorité dynamique.",
								"Le Code du Travail applicable aux salariés.",
								"Notions de droit civil et pénal.",
								"La réalisation des budgets.",
								"La fonction Achat.",
								"Gérer les contrats de maintenance des installations de sécurité.",
								"Examen final en vue de l’obtention du certificat de qualification de Chef de service sécurité incendie SSIAP 3."
							]}
							pedagogie="Enseignement théorique rythmé par les visites de site, les exercices pratiques, les mises en situation et le travail en sous-groupe | Participants : maximum 10"
							evaluationValidation="Examen final comportant une épreuve écrite, une étude de cas, une épreuve orale devant un jury composé de professionnels. En cas de succès, remise du diplôme de Chef de service sécurité incendie et d’assistance à la personne (SSIAP 3)."
							recyclage="Un recyclage est obligatoire tous les 3 ans afin de maintenir sa qualification et pouvoir exercer la fonction de Chef de service sécurité incendie."
						></AccordionBase>
						<AccordionBase
							numberKey="3"
							titre="Recyclage triennal - SSIAP 1"
							duree="1 jour et demi / 10 heures"
							prix="165 € HT"
							objectif={[
								"Acquérir une instruction technique spécialisée en matière de prévention, de détection et de lutte contre l’incendie, ainsi que d’entretien des moyens de secours.",
							]}
							participants={[
								"Agents de sécurité incendie en ERP-IGH."
							]}
							preRequis={[
								"Être titulaire du certificat de qualification SSIAP 1 et justifier d’une activité de 3 ans (au moins 1607 heures).",
								"Être titulaire du recyclage de moins de 2 ans en matière de secourisme."
							]}
							programme={[
								"Mise en œuvre des moyens de lutte contre le feu.",
								"Rappel sur les dispositions réglementaires des ERP et IGH.",
								"Réalisation de ronde de sécurité avec incidents."
							]}
						></AccordionBase>
						<AccordionBase
							numberKey="4"
							titre="Recyclage triennal - SSIAP 2"
							duree="2 jours / 14 heures"
							prix="185 € HT"
							objectif={[
								"Acquérir une instruction technique spécialisée en matière de prévention, de détection et de lutte contre l’incendie, ainsi que d’entretien des moyens de secours."
							]}
							participants={[
								"Chefs d’équipe de sécurité incendie en ERP-IGH."
							]}
							preRequis={[
								"Être titulaire du recyclage de moins de 2 ans en matière de secourisme.",
								"Être titulaire du certificat de qualification SSIAP 2 et justifier d’une activité de 3 ans (au moins 1607 heures)."
							]}
							programme={[
								"Organiser une séance de formation.",
								"Rappel sur les dispositions réglementaires des ERP et IGH.",
								"Mise en œuvre des moyens de lutte contre le feu.",
								"Management de l’équipe sécurité incendie."
							]}
						></AccordionBase>
						<AccordionBase
							numberKey="5"
							titre="Recyclage triennal - SSIAP 3"
							duree="3 jours / 21 heures"
							prix="340 € HT"
							objectif={[
								"Acquérir une instruction technique spécialisée en matière de prévention, de détection et de lutte contre l’incendie, ainsi que d’entretien des moyens de secours."
							]}
							participants={[
								"Chefs de service sécurité incendie en ERP-IGH en activité depuis 3 ans."
							]}
							preRequis={[
								"Être titulaire du recyclage de moins de 2 ans en matière de secourisme.",
								"Être titulaire du certificat de qualification SSIAP 3 en activité depuis 3 ans (au moins 1607 heures)."
							]}
							programme={[
								"Rappel sur les dispositions réglementaires des ERP et IGH.",
								"Les commissions de sécurité.",
								"Management de l’équipe sécurité incendie.",
								"Étude de cas."
							]}
							pedagogie="Alternance d’enseignement théorique, de retours d’expérience des stagiaires et d’études de cas | Participants : maximum 10"
							evaluationValidation="Délivrance d’une attestation de recyclage SSIAP 3."
						></AccordionBase>
						<AccordionBase
							numberKey="6"
							titre="Remise à niveau - SSIAP 1"
							duree="3 jours / 21 heures"
							prix="245 € HT"
							objectif={[
								"Acquérir une instruction technique spécialisée en matière de prévention, de détection et de lutte contre l’incendie, ainsi que d’entretien des moyens de secours."
							]}
							participants={[
								"Toute personne voulant exercer la fonction d’agent de sécurité incendie en ERP-IGH."
							]}
							preRequis={[
								"Être titulaire du certificat de qualification SSIAP 1 et avoir eu une activité de moins de 1607 heures durant les 3 dernières années, ou être titulaire de l’ERP 1 ou IGH 1 et souhaiter devenir titulaire du diplôme SSIAP 1 par équivalence.",
								"Être titulaire de l’attestation de AFPS, PSC 1 ou du SST valide de moins d’un an."
							]}
							programme={[
								"Le poste de sécurité incendie",
								"Les rondes de sécurité et la surveillance des travaux",
								"Appel et réception des services publics de secours",
								"Mise en situation d’intervention"
							]}
							pedagogie="Enseignement théorique ponctué d’exercices de mise en pratique | Participants : maximum 10"
							evaluationValidation="Délivrance d’une attestation de remise à niveau ou du diplôme SSIAP 3 par équivalence."
						></AccordionBase>
						<AccordionBase
							numberKey="7"
							titre="Remise à niveau - SSIAP 2"
							duree="3 jours / 21 heures"
							prix="265 € HT"
							objectif={[
								"Acquérir une instruction technique spécialisée en matière de prévention, de détection et de lutte contre l’incendie, ainsi que d’entretien des moyens de secours."
							]}
							participants={[
								"Toute personne voulant exercer la fonction de Chef d’équipe sécurité incendie en ERP-IGH."
							]}
							preRequis={[
								"Être titulaire du certificat de qualification SSIAP 2 et avoir eu une activité de moins de 1607 heures durant les 3 dernières années, ou être titulaire de la qualification ERP 2 ou IGH 2 et souhaiter obtenir la qualification SSIAP 2 par équivalence.",
								"Être titulaire du recyclage de moins de 2 ans en matière de secourisme."
							]}
							programme={[
								"Management de l’équipe sécurité",
								"Évaluation de l’équipe",
								"Information de la hiérarchie",
								"Gestion du poste central de sécurité"
							]}
						></AccordionBase>
						<AccordionBase
							numberKey="8"
							titre="Remise à niveau - SSIAP 3"
							duree="5 jours / 35 heures"
							prix="490 € HT"
							objectif={[
								"Acquérir une instruction technique spécialisée en matière de prévention, de détection et de lutte contre l’incendie, ainsi que d’entretien des moyens de secours."
							]}
							participants={[
								"Chefs de service sécurité incendie en ERP-IGH 3 ou équivalence, en non-activité depuis 3 ans."
							]}
							preRequis={[
								"Être titulaire du recyclage de moins de 2 ans en matière de secourisme.",
								"Être titulaire du certificat de qualification SSIAP 3 et avoir eu une activité de moins de 1607 heures durant les 3 dernières années, ou être titulaire du diplôme ERP-IGH 3 (ou équivalence) et souhaiter obtenir la qualification SSIAP 3 par équivalence."
							]}
							programme={[
								"Les documents administratifs",
								"Le registre de sécurité incendie",
								"Organiser le service de sécurité incendie"
							]}
						></AccordionBase>
					</Accordion>
					<FooterFormation
						nomFormation="Sécurité Incendie"
						listeFormations={["SSIAP - Niveau 1", "SSIAP - Niveau 2", "SSIAP - Niveau 3", "Recyclage triennal - SSIAP 1", "Recyclage triennal - SSIAP 2", "Recyclage triennal - SSIAP 3", "Remise à niveau - SSIAP 1", "Remise à niveau - SSIAP 2", "Remise à niveau - SSIAP 3"]}
						downloadLink={"SI.pdf"}
					></FooterFormation>
				</div>
				<FormationText
					objectif="La formation Sécurité Incendie a pour objectif de fournir aux participants les connaissances et compétences essentielles pour assumer le rôle d'Agent de Sécurité Incendie. Elle englobe différents niveaux, du débutant à l'expert, couvrant les aspects de prévention, de détection et de lutte contre l'incendie. Cette formation nécessite une mise à jour périodique tous les 3 ans pour garantir une expertise constamment actualisée."
					downloadLink="SI.pdf"
					nomFormation="Sécurité Incendie"
					listeFormations={["SSIAP - Niveau 1", "SSIAP - Niveau 2", "SSIAP - Niveau 3", "Recyclage triennal - SSIAP 1", "Recyclage triennal - SSIAP 2", "Recyclage triennal - SSIAP 3", "Remise à niveau - SSIAP 1", "Remise à niveau - SSIAP 2", "Remise à niveau - SSIAP 3"]}
					tauxReussite="91 %"
				></FormationText>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default FormationSecuriteIncendie