import React from "react";
import '../views/formations-page.css'
import ImageWithLoading from "./image-loader";
import Swal from "sweetalert2";
import FormDevis from "./form-devis";
import ReactDOM from "react-dom";
import propTypes from 'prop-types';

const FooterFormation = (props) => {

    function downloadBrochure() {
		window.open("/brochures/" + props.downloadLink, '_blank');
	}

	function demandeDevis() {
		const modalComponent = document.createElement("div");
		modalComponent.style.textAlign = "left";
		ReactDOM.render(<FormDevis nomFormation={props.nomFormation} listeFormations={props.listeFormations} />, modalComponent)

		Swal.fire({
			title: 'Demande de devis',
			html: modalComponent,
			showCloseButton: true,
			showCancelButton: false,
			showConfirmButton: false
		})
	}

    return (
        <div className="div-certif-download">
            <div className="row-certification">
                <ImageWithLoading src="/external/certibat-certif.png" alt="certibat-certif" className="certif-icon" />
                <ImageWithLoading src="/external/qualiopi.png" alt="qualiopi-certif" className="certif-icon" />
            </div>
            <div className="d-flex flex-column justify-content-center">
                <button onClick={demandeDevis} className="btn btn-download mb-2 mt-2">Demander un devis</button>
            	{ props.downloadLink != "" &&
				(<button onClick={downloadBrochure} className="btn btn-primary">Télécharger la brochure</button>)}
            </div>
        </div>
    )
}

FooterFormation.defaultProps = {
	downloadLink: "RE.pdf",
	nomFormation: "",
	listeFormations: []
}

FooterFormation.propTypes = {
	downloadLink: propTypes.string,
	nomFormation: propTypes.string,
	listeFormations: propTypes.array
}

export default FooterFormation;