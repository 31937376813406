import Form from 'react-bootstrap/Form';
import emailjs from '@emailjs/browser';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { checkInput } from '../formValidator';


import React from 'react';
import Swal from 'sweetalert2';

const FormDevis = (props) => {
	const [validated, setValidated] = useState(false);

	const sendMail = (e) => {
		const form = e.currentTarget;
		setValidated(true);
		if (form.checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
		} else {
			e.preventDefault();
			var formFields = e.target.elements;
			emailjs.init('XBD6WIhY8PLVhOE9x');
			var templateParams = {
				nom: formFields["nom"].value,
				societe: formFields["societe"].value,
				nbr: formFields["nbr"].value,
				mail: formFields["mail"].value,
				numero: formFields["numero"].value,
				nomFormation: props.nomFormation,
				type_formation: formFields["type_formation"].value
			};
			emailjs.send('service_c8lqn9d', 'template_ujr95d4', templateParams)
				.then((result) => {
					Swal.fire({
						title: 'Demande de devis',
						text: 'Votre demande a bien été envoyée, vous recevrez une réponse dans les plus brefs délais',
						icon: 'success',
						confirmButtonText: 'Ok'
					})
				}, (error) => {
					console.log(error.text);
				});
		}
	}

	return (
		<Form noValidate validated={validated} onSubmit={sendMail}>
			<Form.Group className="mb-3">
				<Form.Label>Nom <span className="text-danger">*</span></Form.Label>
				<Form.Control required name="nom" type="text" placeholder="Nom" />
				<Form.Control.Feedback type="invalid">Veuillez entrer un nom</Form.Control.Feedback>
			</Form.Group>
			<Form.Group className="mb-3">
				<Form.Label>Société</Form.Label>
				<Form.Control type="text" name="societe" placeholder="Société" />
			</Form.Group>
			<Form.Group className="mb-3">
				<Form.Label>E-mail <span className="text-danger">*</span></Form.Label>
				<Form.Control type="email" required name="mail" placeholder="E-mail" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />
				<Form.Control.Feedback type="invalid">Veuillez entrer un email</Form.Control.Feedback>
			</Form.Group>
			<Form.Group className="mb-3">
				<Form.Label>Numéro de télephone <span className="text-danger">*</span></Form.Label>
				<Form.Control type="text" required name="numero" placeholder="Tél" onKeyDown={checkInput} />
				<Form.Control.Feedback type="invalid">Veuillez entrer un numéro</Form.Control.Feedback>
			</Form.Group>
			<Form.Group className="mb-3">
				<Form.Label >Nombre de personnes <span className="text-danger">*</span></Form.Label>
				<Form.Control required type="number" name="nbr" placeholder="Nbr pers." />
				<Form.Control.Feedback type="invalid">Veuillez entrer un nombre</Form.Control.Feedback>
			</Form.Group>
			<Form.Group className="mb-3">
				<Form.Label>Formation souhaitée <span className="text-danger">*</span></Form.Label>
				<Form.Select name='type_formation'>
					{props.listeFormations.map((element, key) => (
						<option key={key} value={element}>{element}</option>
					))}
				</Form.Select>
			</Form.Group>
			<div className="w-100 d-flex justify-content-center">
				<button type="submit" className="btn btn-primary">Envoyer</button>
			</div>
		</Form>
	);
}

FormDevis.defaultProps = {
	nomFormation: "",
	listeFormations: []
}

FormDevis.propTypes = {
	nomFormation: PropTypes.string,
	listeFormations: PropTypes.array
}

export default FormDevis;
